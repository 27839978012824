

import AbstractComponentVue from '../services/AbstractComponentVue';
import {Component} from 'vue-property-decorator';
import {services} from '@/main';
import {EnumUserServiceType} from '@/services/dto/IDtos';
import UserCard from '@/components/UserCard.vue';
import {IUserResponse} from '@/services/dto/IUserResponse';
import {IUserService} from '@/services/dto/IUserService';
import AlertService from '@/utils/AlertService';
import UserServicesUtils from '@/services/UserServicesUtils';


@Component({
	components: {
		UserCard,
	},
})
export default class UserDetail extends AbstractComponentVue {
  private accountId: number = +this.$route.params.id;
  private isLoading: boolean = true;
  private visioService: IUserService = {} as IUserService;

  private mounted() {
    this.loadService(this.accountId);
  }

  private loadService(id: number): void {
    services.restService.callGet('/api/v1/accounts/' + id)
        .then((res) => {
          const userResponse: IUserResponse = res.data;
          this.visioService = UserServicesUtils.findUserServiceByType(userResponse, EnumUserServiceType.VISIO_SOFT);
        })
        .finally(() => {
          this.isLoading = false;
        });
  }

  /**
   * Annule la désactivation.
   */
  private cancel(): void {
    services.routageService.goTo('/account/' + this.accountId + '/services');
  }

  /**
   * Désactive le logiciel visio.
   */
  private deactivateVisio(): void {
    this.showModal('modal-traitement-cours');
    const uri: string = '/api/v1/user-services/visio-soft/' + this.accountId + '/deactivate';
    services.restService.callDelete(uri)
        .then((res) => {
          if (res !== undefined && res.request.status === 200) {
            AlertService.success('Le service Visioconférence a été résilié avec succès.');
            services.routageService.goTo('/account/' + this.accountId + '/services');

            services.loginService.ifCurrentUserReloadStore(this.accountId,
                () => this.hideModal('modal-traitement-cours'));
          }
        })
        .catch((resp) => {
          if (resp.request.status === 404) {
            // on affiche que l'utilisateur n'a pas été trouvé.
            AlertService.warning('L\'utilisateur concerné n\'a pas été trouvé.');
          } else if (resp.request.status === 403) {
            // Accès interdit
            AlertService.warning('Accès interdit. Vous ne disposez pas des autorisations nécessaires.');
          } else {
            console.error(resp);
          }
        })
        .finally(() => {
          this.hideModal('modal-traitement-cours');
        });
  }
}
